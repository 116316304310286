import React, { useState, useEffect } from 'react';
import SimpleImageSlider from 'react-simple-image-slider';
import {
  Col,
  Row,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert
} from 'reactstrap';
import { Link } from 'react-router-dom';
import VehiclePackage from '../../../pages/IRides/Vehicle/components/VehiclePackage';
import Moment from 'moment';

const UserVehicleCard = ({
  updateVehiclePackages,
  updateVehicleFailed,
  message,
  vehicleError,
  user,
  handleVehicleApproval,
}) => {
  const [statusModal, setStatusModal] = useState(false);
  const [res, setRes] = useState('');

  const vehicleInfo =
    user?.vehicles &&
    user?.vehicles.find((vehicle) => vehicle.isDeleted === false);

  const images = [
    { url: vehicleInfo?.imageFront },
    vehicleInfo?.imageLeft ? { url: vehicleInfo?.imageLeft } : null,
    vehicleInfo?.imageBack ? { url: vehicleInfo?.imageBack } : null,
    vehicleInfo?.imageRight ? { url: vehicleInfo?.imageRight } : null,
  ];

  useEffect(() => {
    if (message) {
      setRes(message);
      setTimeout(() => {
        setStatusModal(false);
        updateVehicleFailed('');
      }, 2000);
    }
  }, [message]);

  if (vehicleInfo) {
    return (
      <div>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {vehicleError && (
          <Alert color="danger" className="text-center">
            {vehicleError}
          </Alert>
        )}
        <Row style={{ justifyContent: 'space-evenly' }}>
          <Col sm={5} className="border rounded p-4">
            <Row className="justify-content-between">
              <h4 className="text-capitalize card-title mb-5 mt-2">
                {' '}
                Vehicle Details{' '}
              </h4>
            </Row>

            <Table hover>
              <tbody>
                <tr>
                  <td className="text-muted text-right col-6">Vehicle Brand</td>
                  <td className="font-weight-bold text-left col-6">
                    {vehicleInfo?.make}{' '}
                    <h3
                      className={`badge badge-soft-${vehicleInfo?.status.toLowerCase() === 'approved'
                        ? 'success'
                        : vehicleInfo?.status.toLowerCase() === 'cancelled'
                          ? 'danger'
                          : vehicleInfo?.status.toLowerCase() === 'rejected'
                            ? 'danger'
                            : vehicleInfo?.status.toLowerCase() === 'pending'
                              ? 'warning'
                              : vehicleInfo?.status.toLowerCase() === 'review'
                                ? 'dark'
                                : ''
                        } font-size-9 ml-2 p-1`}
                    >
                      {vehicleInfo?.status}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td className="text-muted text-right col-6">Vehicle Year</td>
                  <td className="font-weight-bold text-left col-6">
                    {vehicleInfo?.year}
                  </td>
                </tr>
                <tr>
                  <td className="text-muted text-right col-6">Model</td>
                  <td className="font-weight-bold text-left col-6">
                    {vehicleInfo?.model}
                  </td>
                </tr>
                <tr>
                  <td className="text-muted text-right col-6">Color</td>
                  <td className="font-weight-bolder text-left col-6">
                    {vehicleInfo?.color}
                  </td>
                </tr>
                <tr>
                  <td className="text-muted text-right col-6">
                    Inspection Status
                  </td>
                  <td className="font-weight-bolder text-left col-6">---</td>
                </tr>
                <tr>
                  <td className="text-muted text-right col-6">Capacity</td>
                  <td className="font-weight-bold text-lef col-6t">
                    {vehicleInfo?.seats}
                  </td>
                </tr>
                <tr>
                  <td className="text-muted text-right col-6">Plate Number</td>
                  <td className="font-weight-bold text-left col-6">
                    {vehicleInfo?.plateNumber}
                  </td>
                </tr>
                <tr>
                  <td className="text-muted text-right col-6">Created At</td>
                  <td className="font-weight-bold text-left col-6">
                    {Moment(vehicleInfo?.createdAt).format('l')}
                  </td>
                </tr>
                <tr>
                  <td className="text-muted text-right col-6">Packages</td>
                  <td className="font-weight-bold text-left d-flex align-items-center">
                    <div>
                      {vehicleInfo?.packages?.length > 0
                        ? vehicleInfo.packages.map((pkg) => (
                          <span key={pkg.id}>
                            <span
                              className={`badge text-white font-size-1 mr-2`}
                              style={{ background: pkg?.colorCode }}
                            >
                              {' '}
                              {pkg.name}
                            </span>
                          </span>
                        ))
                        : 'No package'}
                    </div>
                    <Button
                      size="sm"
                      color="success"
                      onClick={() => setStatusModal(true)}
                      className="ml-2"
                    >
                      <i className="mdi mdi-plus-thick font-size-12"></i>{' '}
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col md={6} className="border rounded p-4">
            <Row className="justify-content-between">
              <h4 className="text-capitalize card-title mb-3 mt-2">
                {' '}
                Vehicle Image{' '}
              </h4>
            </Row>
            <Row className="align-self-center ">
              <SimpleImageSlider
                width={'-webkit-fill-available'}
                height={'25rem'}
                images={images}
                showBullets={true}
                showNavs={true}
              />
              <div
                style={{ width: '-webkit-fill-available' }}
                className=" mt-2 d-flex justify-content-end"
              >
                <Link
                  to="#"
                  onClick={() =>
                    handleVehicleApproval(vehicleInfo.id, 'REJECTED')
                  }
                  style={
                    vehicleInfo.status === 'REJECTED'
                      ? { pointerEvents: 'none', opacity: '0.5' }
                      : null
                  }
                >
                  <Button color={'danger'} outline size="sm" className="px-3">
                    Reject
                  </Button>
                </Link>
                <Link
                  to="#"
                  onClick={() =>
                    handleVehicleApproval(vehicleInfo.id, 'APPROVED')
                  }
                  style={
                    vehicleInfo.status === 'APPROVED'
                      ? { pointerEvents: 'none', opacity: '0.5' }
                      : null
                  }
                >
                  <Button color={'success'} className="ml-2 px-3" size="sm">
                    Approve
                  </Button>
                </Link>
              </div>
            </Row>
          </Col>
        </Row>

        {/* Vehicle package Update Modal */}
        <Modal isOpen={statusModal} toggle={() => setStatusModal(!statusModal)}>
          <ModalHeader toggle={() => setStatusModal(false)}>
            Update Vehicle Package
          </ModalHeader>
          <ModalBody>
            {message && (
              <Alert color="success" className="text-center">
                {message}
              </Alert>
            )}
            {vehicleError && (
              <Alert color="danger" className="text-center">
                {vehicleError}
              </Alert>
            )}
            <VehiclePackage
              vehicleDetails={vehicleInfo}
              updateVehiclePackages={updateVehiclePackages}
              message={res}
              closeModal={setStatusModal}
              updateVehicleFailed={updateVehicleFailed}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  } else {
    return <div>This driver has no registered vehicle</div>;
  }
};

export default UserVehicleCard;
