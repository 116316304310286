import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from "reactstrap";
import { connect } from "react-redux";
import { getS3ImageUrlHandler } from "../../../../utils/general";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import AutoComplete from "../../../../components/Common/Autocomplete";
import CustomSelect from "../../../../components/Common/MoreDropdown/CustomSelect";

// actions
import {
  createVehicle,
  createVehicleFailed,
  updateVehicle,
  updateVehicleFailed,
  fetchUserDetails,
  updateVehiclePackages,
} from "../../../../store/actions";
const Form = ({
  createVehicle,
  createVehicleFailed,
  updateVehicle,
  updateVehicleFailed,
  message,
  user,
  history,
  fetchUserDetails,
  vehicleError,
  defaultValue,
  userID,
  packages,
  updateVehiclePackages,
  vId,
  fetchPackage,
}) => {
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setError] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [plateType, setPlateType] = useState("");
  const [selected, setSelected] = useState([]);
  const [packageOption, SetPackageOption] = useState([]);

  const [selectedFrontImage, setFrontImageUpload] = useState([]);
  const [frontImage, setFrontImage] = useState("");
  const [frontImageName, setFrontImageName] = useState("");

  const [selectedBackImage, setBackImageUpload] = useState([]);
  const [backImage, setBackImage] = useState("");
  const [backImageName, setBackImageName] = useState("");

  const [selectedLeftImage, setLeftImageUpload] = useState([]);
  const [leftImage, setLeftImage] = useState("");
  const [leftImageName, setLeftImageName] = useState("");

  const [selectedRightImage, setRightImageUpload] = useState([]);
  const [rightImage, setRightImage] = useState("");
  const [rightImageName, setRightImageName] = useState("");

  const [selectedInsideImage, setInsideImageUpload] = useState([]);
  const [insideImage, setInsideImage] = useState("");
  const [insideImageName, setInsideImageName] = useState("");

  const handleSubmit = async (event, values) => {
    if (!description) {
      setError("Vehicle description can not be empty");
      return;
    }
    if (!selectedUser) {
      setError("Please choose vehicle owner");
      return;
    }
    if (selectedFrontImage.length === 0 && !defaultValue) {
      setError("The vehicle front image is required");
      return;
    }
    setIsLoading(true);
    setError("");
    let frontImageResult,
      backImageResult,
      leftImageResult,
      rightImageResult,
      insideImageResult;
    try {
      const vehicleData = {
        ...values,
      };
      if (selectedFrontImage.length !== 0) {
        frontImageResult = await getS3ImageUrlHandler(
          selectedFrontImage[0],
          "vehicle"
        );
        vehicleData.imageFront = frontImageResult?.data.result?.mediaUrl;
        vehicleData.imageFrontThumb =
          frontImageResult?.data?.result.mediaThumbUrl;
      }
      if (selectedBackImage.length !== 0) {
        backImageResult = await getS3ImageUrlHandler(
          selectedBackImage[0],
          "vehicle"
        );
        vehicleData.imageBack = backImageResult?.data.result?.mediaUrl;
        vehicleData.imageBackThumb =
          backImageResult?.data?.result.mediaThumbUrl;
      }
      if (selectedLeftImage.length !== 0) {
        leftImageResult = await getS3ImageUrlHandler(
          selectedLeftImage[0],
          "vehicle"
        );
        vehicleData.imageLeft = leftImageResult?.data.result?.mediaUrl;
        vehicleData.imageLeftThumb =
          leftImageResult?.data?.result.mediaThumbUrl;
      }
      if (selectedRightImage.length !== 0) {
        rightImageResult = await getS3ImageUrlHandler(
          selectedRightImage[0],
          "vehicle"
        );
        vehicleData.imageRight = rightImageResult?.data.result?.mediaUrl;
        vehicleData.imageRightThumb =
          rightImageResult?.data?.result.mediaThumbUrl;
      }

      if (selectedInsideImage.length !== 0) {
        insideImageResult = await getS3ImageUrlHandler(
          selectedInsideImage[0],
          "vehicle"
        );
        vehicleData.imageInside = insideImageResult?.data.result?.mediaUrl;
        vehicleData.imageInsideThumb =
          insideImageResult?.data?.result.mediaThumbUrl;
      }

      vehicleData.userId = selectedUser.value;
      vehicleData.make = values.make;
      vehicleData.model = values.model;
      vehicleData.plateNumber = values.plateNumber;
      vehicleData.year = Number(values.year);
      vehicleData.description = description;
      vehicleData.color = values.color;
      vehicleData.route = values.route;
      vehicleData.type = values.type;
      vehicleData.configuration = values.configuration;
      vehicleData.seats = Number(values.seats);
      vehicleData.seatRight = Number(values.seatRight);
      vehicleData.seatLeft = Number(values.seatLeft);
      if (defaultValue) {
        //Update Packages
        let packageIds = [];
        selected.forEach((data) => {
          packageIds.push(data.value);
        });
        const data = {
          packages: packageIds,
          id: defaultValue.id,
        };
        updateVehiclePackages(data);

        //Update vehicle data
        vehicleData.id = defaultValue.id;
        vehicleData.status = values.Status;
        updateVehicle(vehicleData);
        setIsLoading(false);
        return;
      }
      createVehicle(vehicleData);
      setIsLoading(false);
    } catch (error) {
      alert("error in image upload");
      setIsLoading(false);
    }
  };

  // Add packages to vehicle on create
  useEffect(() => {
    if (vId && selected.length !== 0) {
      let packageId = [];
      selected.forEach((data) => {
        packageId.push(data.value);
      });
      const data = {
        packages: packageId,
        id: vId,
      };
      updateVehiclePackages(data);
      setSelected([]);
    }
  }, [vId]);

  const frontImageHandler = (e) => {
    const files = e.target.files;
    if (files) {
      const reader = new FileReader();
      reader.onload = () => {
        setFrontImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
      setFrontImageUpload(files);
      setFrontImageName(files[0].name);
    }
  };

  const backImageHandler = (e) => {
    const files = e.target.files;
    if (files) {
      const reader = new FileReader();
      reader.onload = () => {
        setBackImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
      setBackImageUpload(files);
      setBackImageName(files[0].name);
    }
  };

  const leftImageHandler = (e) => {
    const files = e.target.files;
    if (files) {
      const reader = new FileReader();
      reader.onload = () => {
        setLeftImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
      setLeftImageUpload(files);
      setLeftImageName(files[0].name);
    }
  };

  const rightImageHandler = (e) => {
    const files = e.target.files;
    if (files) {
      const reader = new FileReader();
      reader.onload = () => {
        setRightImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
      setRightImageUpload(files);
      setRightImageName(files[0].name);
    }
  };

  const insideImageHandler = (e) => {
    const files = e.target.files;
    if (files) {
      const reader = new FileReader();
      reader.onload = () => {
        setInsideImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
      setInsideImageUpload(files);
      setInsideImageName(files[0].name);
    }
  };

  useEffect(() => {
    if (packages) {
      const packageArr = [];
      packages.result.forEach((element) => {
        const packageObj = {};
        packageObj.value = element.id;
        packageObj.label = element.name;
        packageArr.push(packageObj);
      });
      SetPackageOption(packageArr);
    }
    if (message) {
      setTimeout(() => {
        createVehicleFailed("");
        updateVehicleFailed("");
        history.goBack();
      }, 2000);
    }
  }, [packages, message]);

  useEffect(() => {
    createVehicleFailed("");
    updateVehicleFailed("");
  }, []);

  useEffect(() => {
    if (userID) {
      fetchUserDetails(userID);
    }
  }, []);

  useEffect(() => {
    setDescription("");
    setFrontImage("");
    setBackImage("");
    setLeftImage("");
    setRightImage("");
    setInsideImage("");
    setPlateType("");
    setSelected([]);
    if (defaultValue) {
      setDescription(defaultValue.description);
      setSelectedUser(defaultValue.user.id);
      setFrontImage(defaultValue.imageFront);
      setBackImage(defaultValue.imageBack);
      setLeftImage(defaultValue.imageLeft);
      setRightImage(defaultValue.imageRight);
      setInsideImage(defaultValue.imageInside);
      if (defaultValue.plateNumber.charAt(3) !== "-") {
        setPlateType("custom");
      }
      if (defaultValue?.packages?.length > 0) {
        const pkgArr = [];
        if (defaultValue.packages) {
          defaultValue.packages.forEach((element) => {
            const pkgObj = {};
            pkgObj.value = element.id;
            pkgObj.label = element.name;
            pkgArr.push(pkgObj);
          });
        }
        setSelected(pkgArr);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (userID) {
      setSelectedUser(userID);
    }
  }, [userID]);
  const handleFetchMore = () => {
    fetchPackage({ take: packageOption.length + 10, skip: 0 });
  };
  return (
    <Card>
      <CardBody>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {vehicleError && (
          <Alert color="danger" className="text-center">
            {vehicleError}
          </Alert>
        )}
        {errorMessage && (
          <Alert color="danger" className="text-center">
            {errorMessage}
          </Alert>
        )}
        <div className="float-right">
          <Link to="#" onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left mr-3" />
            Back
          </Link>
        </div>
        <div>
          <h5> {defaultValue ? "Update" : "Create"} Vehicle </h5>
        </div>
        <div>
          <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup className="mb-4">
                  {(() => {
                    if (defaultValue)
                      return (
                        <>
                          <AvField
                            name="userId"
                            type="text"
                            className="form-control bg-light"
                            label="Vehicle Owner"
                            id="userId"
                            value={`${defaultValue?.user.firstName} ${defaultValue?.user.lastName}`}
                            required
                            disabled
                          />
                        </>
                      );
                    if (userID)
                      return (
                        <AvField
                          name="userId"
                          type="text"
                          className="form-control bg-light text-capitalize"
                          label="Vehicle Owner"
                          id="userId"
                          value={`${user?.firstName} ${user?.lastName}`}
                          required
                          disabled
                        />
                      );
                    else
                      return (
                        <AutoComplete
                          setUser={(e) => setSelectedUser(e)}
                          userType={"DRIVER"}
                          title="Vehicle Owner"
                        />
                      );
                  })()}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-4">
                  <Label htmlFor="make">Brand Name</Label>
                  <AvField
                    name="make"
                    type="text"
                    className="form-control bg-light"
                    id="make"
                    value={defaultValue ? defaultValue.make : ""}
                    required
                    placeholder="Enter Vehicle Brand Name ..."
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="model">Model</Label>

                      <AvField
                        name="model"
                        type="text"
                        className="form-control bg-light"
                        id="model"
                        value={defaultValue ? defaultValue.model : ""}
                        required
                        placeholder="Enter Vehicle Model ..."
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className=" mb-4">
                      <Label htmlFor="color"> Color</Label>
                      <AvField
                        name="color"
                        type="text"
                        className="form-control bg-light"
                        id="color"
                        value={defaultValue ? defaultValue.color : ""}
                        required
                        placeholder="Enter Vehicle Color ..."
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-4">
                  <Label htmlFor="plateNumber">Plate Number</Label>
                  <Row>
                    <Col md={3} className="pr-0">
                      <AvField
                        className="form-control bg-light"
                        type="select"
                        name="plateType"
                        onChange={(e) => setPlateType(e.target.value)}
                        style={{
                          borderTopRightRadius: "0",
                          borderBottomRightRadius: "0",
                          borderRight: "none",
                        }}
                        value={plateType}
                      >
                        <option value={""}>Default</option>
                        <option value={"custom"}>Customise</option>
                      </AvField>
                    </Col>
                    <Col md={9} className="pl-0">
                      <AvField
                        name="plateNumber"
                        type="text"
                        className=" form-control bg-light"
                        id="plateNumber"
                        minLength={`${plateType === "custom" ? 2 : 8}`}
                        maxLength={`${plateType === "custom" ? 8 : 9}`}
                        value={defaultValue ? defaultValue.plateNumber : ""}
                        required
                        validate={
                          plateType !== "custom"
                            ? {
                                pattern: {
                                  value:
                                    /^\S*([A-Z]{3}[\W]{1}[0-9]{2,3}[A-Z]{2})$/,
                                  errorMessage:
                                    'Plate number can only be in this format "ABC-123DE"',
                                },
                              }
                            : null
                        }
                        style={{
                          borderTopLeftRadius: "0",
                          borderBottomLeftRadius: "0",
                        }}
                        errorMessage={`${
                          plateType === "custom"
                            ? "min of 2 characters"
                            : "min of 7 characters"
                        }`}
                        placeholder="Enter Vehicle Plate Number ..."
                        helpMessage={`${
                          plateType === "custom"
                            ? ""
                            : 'Plate number should be in Upper case with no space "ABC-123DE"'
                        }`}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <FormGroup className=" mb-4">
                      <AvField
                        className="form-control bg-light"
                        type="select"
                        name="type"
                        label="Type"
                        value={defaultValue ? defaultValue.type : "RYDE"}
                        required
                      >
                        {" "}
                        <option value={"RYDE"}>CAR</option>
                        <option value={"TRYKE"}>TRYKE</option>
                        <option value={"BYKE"}>BYKE</option>
                        <option value={"MINI_BUS"}>MINI BUS</option>
                        <option value={"HUMMER_BUS"}>HUMMER BUS</option>
                        <option value={"COASTER_BUS"}>COASTER BUS</option>
                        <option value={"BRT_BUS"}>BRT BUS</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className=" mb-4">
                      <AvField
                        className="form-control bg-light"
                        type="select"
                        name="configuration	"
                        label="Configuration"
                        value={
                          defaultValue ? defaultValue.configuration : "TYPE_1"
                        }
                        required
                      >
                        {" "}
                        <option value={"TYPE_1"}>Type 1</option>
                        <option value={"TYPE_2"}>Type 2</option>
                        <option value={"TYPE_3"}>Type 3</option>
                        <option value={"TYPE_4"}>Type 4</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <FormGroup className=" mb-4">
                  <Label htmlFor="year"> Year</Label>

                  <AvField
                    name="year"
                    type="number"
                    min="2000"
                    max="2030"
                    step="1"
                    className="form-control bg-light"
                    id="year"
                    value={defaultValue ? defaultValue.year : ""}
                    required
                    errorMessage=""
                    helpMessage="from year 2000 upward"
                    placeholder="Enter Vehicle Year ..."
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup className=" mb-4">
                  <Label> Vehicle Front View </Label>
                  <div
                    className={
                      frontImage ? "d-flex justify-content-between" : null
                    }
                  >
                    <div>
                      <div className="mb-3 input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            accept="image/*"
                            name="image-upload"
                            className="custom-file-input"
                            id="frontImage"
                            aria-describedby="frontImage"
                            onChange={(e) => frontImageHandler(e)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="frontImage"
                          >
                            Choose file ...
                          </label>
                        </div>
                      </div>
                      <span className="font-italic">{frontImageName}</span>
                    </div>
                    {frontImage ? (
                      <img
                        data-dz-thumbnail=""
                        height="100"
                        className="rounded bg-light"
                        alt={frontImageName}
                        src={frontImage}
                      />
                    ) : null}
                  </div>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className=" mb-4">
                  <Label> Vehicle Rear View </Label>
                  <div
                    className={
                      backImage ? "d-flex justify-content-between" : null
                    }
                  >
                    <div>
                      <div className="mb-3 input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            accept="image/*"
                            name="image-upload"
                            className="custom-file-input"
                            id="backImage"
                            aria-describedby="backImage"
                            onChange={(e) => backImageHandler(e)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="backImage"
                          >
                            Choose file ...
                          </label>
                        </div>
                      </div>
                      <span className="font-italic">{backImageName}</span>
                    </div>
                    {backImage ? (
                      <img
                        data-dz-thumbnail=""
                        height="100"
                        className="rounded bg-light"
                        alt={backImageName}
                        src={backImage}
                      />
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className=" mb-4">
                  <Label> Vehicle Left Side View </Label>
                  <div
                    className={
                      leftImage ? "d-flex justify-content-between" : null
                    }
                  >
                    <div>
                      <div className="mb-3 input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            accept="image/*"
                            name="image-upload"
                            className="custom-file-input"
                            id="leftImage"
                            aria-describedby="leftImage"
                            onChange={(e) => leftImageHandler(e)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="leftImage"
                          >
                            Choose file ...
                          </label>
                        </div>
                      </div>
                      <span className=" font-italic">{leftImageName}</span>
                    </div>
                    {leftImage ? (
                      <img
                        data-dz-thumbnail=""
                        height="100"
                        className="rounded bg-light"
                        alt={leftImageName}
                        src={leftImage}
                      />
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className=" mb-4">
                  <Label> Vehicle Right Side View </Label>
                  <div
                    className={
                      rightImage ? "d-flex justify-content-between" : null
                    }
                  >
                    <div>
                      <div className="mb-3 input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            accept="image/*"
                            name="image-upload"
                            className="custom-file-input"
                            id="rightImage"
                            aria-describedby="rightImage"
                            onChange={(e) => rightImageHandler(e)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="rightImage"
                          >
                            Choose file ...
                          </label>
                        </div>
                      </div>
                      <span className=" font-italic">{rightImageName}</span>
                    </div>
                    {rightImage ? (
                      <img
                        data-dz-thumbnail=""
                        height="100"
                        className="rounded bg-light"
                        alt={rightImageName}
                        src={rightImage}
                      />
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className=" mb-4">
                  <Label> Vehicle Inside View </Label>
                  <div
                    className={
                      insideImage ? "d-flex justify-content-between" : null
                    }
                  >
                    <div>
                      <div className="mb-3 input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            accept="image/*"
                            name="image-upload"
                            className="custom-file-input"
                            id="insideImage"
                            aria-describedby="insideImage"
                            onChange={(e) => insideImageHandler(e)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="insideImage"
                          >
                            Choose file ...
                          </label>
                        </div>
                      </div>
                      <span className=" font-italic">{insideImageName}</span>
                    </div>
                    {insideImage ? (
                      <img
                        data-dz-thumbnail=""
                        height="100"
                        className="rounded bg-light"
                        alt={insideImageName}
                        src={insideImage}
                      />
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className=" mb-4">
                  <Label htmlFor="route">Preferred Route</Label>
                  <AvField
                    name="route"
                    type="text"
                    className="form-control bg-light"
                    id="route"
                    value={defaultValue ? defaultValue.route : ""}
                    errorMessage=""
                    placeholder="Enter your most preferred route ..."
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup className=" mb-4">
                  <Label htmlFor="seats"> Vehicle Capacity</Label>
                  <AvField
                    name="seats"
                    type="number"
                    min="1"
                    className="form-control bg-light"
                    id="seats"
                    value={defaultValue ? defaultValue.seats : ""}
                    required
                    errorMessage=""
                    placeholder="Enter Number of Seats ..."
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="packages"> Packages</Label>

                  <div className="CustomSelect">
                    <CustomSelect
                      options={packageOption}
                      selected={selected}
                      onChange={setSelected}
                      fetchMore={
                        packageOption.length !== packages?.total
                          ? handleFetchMore
                          : null
                      }
                      labelledBy="Select packages ..."
                    ></CustomSelect>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className=" mb-4">
                  <Label htmlFor="seatRight">Seats on the right side</Label>
                  <AvField
                    name="seatRight"
                    type="number"
                    min="1"
                    className="form-control bg-light"
                    id="seatRight"
                    value={defaultValue ? defaultValue.seatRight : ""}
                    errorMessage=""
                    placeholder="Enter Number of seats on the right side ..."
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className=" mb-4">
                  <Label htmlFor="seatLeft">Seats on the left side</Label>
                  <AvField
                    name="seatLeft"
                    type="number"
                    min="1"
                    className="form-control bg-light"
                    id="seatLeft"
                    value={defaultValue ? defaultValue.seatLeft : ""}
                    errorMessage=""
                    placeholder="Enter Number of seats on the left side ..."
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              {defaultValue ? (
                <Col md={6}>
                  <FormGroup className=" mb-4">
                    <AvField
                      className="form-control bg-light"
                      type="select"
                      name="Status"
                      label="Status"
                      value={defaultValue ? defaultValue.status : "Pending"}
                    >
                      <option value={"REVIEW"}>Review</option>
                      <option value={"PENDING"}>Pending</option>
                      <option value={"APPROVED"}>Approve</option>
                      <option value={"REJECTED"}>Reject</option>
                    </AvField>
                  </FormGroup>
                </Col>
              ) : null}

              <Col md={6}>
                <FormGroup className=" mb-4">
                  <Label htmlFor="description"> Description</Label>
                  <textarea
                    className="form-control"
                    onChange={(e) => setDescription(e.target.value)}
                    id="description"
                    value={description}
                    rows="5"
                    // ref={descriptionRef}
                  ></textarea>
                </FormGroup>
              </Col>
            </Row>
            {defaultValue ? (
              <button className="btn btn-success mr-2" type="submit">
                {isLoading ? "Submitting ..." : "Update"}
              </button>
            ) : (
              <button className="btn btn-success mr-2" type="submit">
                {isLoading ? "Submitting ..." : "Save"}
              </button>
            )}

            <Link to="#" onClick={() => history.goBack()}>
              <button className="btn btn-secondary">Cancel</button>
            </Link>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, vehicleError, vId } = state.Vehicle;
  const { user, userError } = state.Users;
  return { user, message, loading, vehicleError, userError };
};

export default connect(mapStateToProps, {
  createVehicle,
  updateVehicle,
  createVehicleFailed,
  updateVehicleFailed,
  fetchUserDetails,
  updateVehiclePackages,
})(withRouter(Form));
