import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import SendbirdApp from "@sendbird/uikit-react/App";
import "@sendbird/uikit-react/dist/index.css";
import { fetchUser } from "../../../store/actions";
import CustomizedApp from "./CustomizedApp";

// const appId = process.env.REACT_APP_SENDBIRD_APPID;
// const apiToken = process.env.REACT_APP_SENDBIRD_API_KEY;

const appId = "8FA171A1-2BF1-4C80-8B51-E01B4B6D1CE9";
const apiToken = "9d93da5410bb0d7c4182ea54d3204ecc4f252835";
const avatarImg =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

const Chat = ({ user, fetchUser, userDetails }) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  useEffect(() => {
    if (id) {
      fetchUser(id);
    }
  }, [fetchUser, id]);

  useEffect(() => {
    if (userDetails) {
      const createGroupChannel = async () => {
        const apiUrl = `https://api-${appId}.sendbird.com/v3/group_channels`;
        const headers = {
          "Content-Type": "application/json; charset=utf8",
          "Api-Token": `${apiToken}`,
        };

        const requestBody = {
          name: `${userDetails?.firstName} + ${user.firstName}`,
          channel_url: `sendbird_group_channel_${userDetails?.id}hijklmno`,
          cover_url: `${
            userDetails?.picture ? userDetails?.picture : avatarImg
          }`,
          custom_type: "",
          is_distinct: true,
          // is_public: true,
          user_ids: [
            `${
              userDetails?.firstName + "-" + userDetails?.id.substring(9, 13)
            }`,
            `${user.firstName + "-" + user.id.substring(9, 13)}`,
          ],
          operator_ids: [`${user.firstName + "-" + user.id.substring(9, 13)}`],
        };

        const messageBody = {
          message_type: "MESG",
          user_id: `${user.firstName + "-" + user.id.substring(9, 13)}`,
          send_push: true,
          message: `Hi, ${user.firstName} here from nRyde`,
        };
        // try {
        //   const response = await fetch(
        //     `https://api-${appId}.sendbird.com/v3/users/${
        //       user.firstName + "-" + user.id.substring(9, 13)
        //     }/token`,
        //     {
        //       method: "POST",
        //       headers: headers,
        //       body: JSON.stringify({ expires_at: 1598651815000 }),
        //     }
        //   );
        //   if (response.ok) {
        //     const responseData = await response.json();
        //     console.log("Session Token received:", responseData);
        //   } else {
        //     console.error(
        //       "Error in sending message to channel:",
        //       response.statusText
        //     );
        //   }
        // } catch (error) {
        //   console.error("Error creating group channel:", error);
        // }
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestBody),
          });

          if (response.ok) {
            const responseData = await response.json();
            console.log("Group channel created:", responseData);

            try {
              const response = await fetch(
                `https://api-${appId}.sendbird.com/v3/group_channels/${responseData.channel_url}/messages`,
                {
                  method: "POST",
                  headers: headers,
                  body: JSON.stringify(messageBody),
                }
              );
              if (response.ok) {
                const responseData = await response.json();
                console.log("Group Message Sent:", responseData);
              } else {
                console.error(
                  "Error in sending message to channel:",
                  response.statusText
                );
              }
            } catch (error) {
              console.error("Error creating group channel:", error);
            }
          } else {
            console.error("Error creating group channel:", response.statusText);
          }
        } catch (error) {
          console.error("Error creating group channel:", error);
        }
      };
      createGroupChannel();
    }
  }, [user.firstName, user.id, userDetails]);

  const myColorSet = {
    "--sendbird-light-primary-500": "#0ab7921f",
    "--sendbird-light-primary-400": "#00a884",
    "--sendbird-light-primary-300": "#0ab792",
    "--sendbird-light-primary-200": "#00a884",
    "--sendbird-light-primary-100": "#d4eee9",
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <div>
          <input
            type="text"
            placeholder="Enter channel name"
            value={channelName}
            onChange={(e) => setChannelName(e.target.value)}
          />
          <button onClick={handleCreateChannel}>Create Channel</button>
        </div> */}
        <div className="App" style={{ height: "100vh", width: "100%" }}>
          <SendbirdApp
            appId={appId}
            userId={user.firstName + "-" + user.id.substring(9, 13)}
            colorSet={myColorSet}
          >
            {/* <CustomizedApp /> */}
          </SendbirdApp>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { user, loading, userError, message } = state.Account;
  const { userDetails } = state.Users;
  return { userDetails, user, loading, userError, message };
};

export default connect(mapStateToProps, { fetchUser })(Chat);
