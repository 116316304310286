import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Form from './form';

import { fetchEachZone } from '../../../../store/actions';

const ZoneForm = ({ fetchEachZone, zonePreview, loading, message }) => {
  const url = useLocation().search;
  let search = url.split('?');
  const id = search[search.length - 3];
  const editedCordTable = search[search.length - 2];
  const editedCordMap = search[search.length - 1];
  const newCord = new URLSearchParams(url).get('cord');

  useEffect(() => {
    if (id) {
      fetchEachZone(id);
    }
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        {zonePreview ? (
          <Form
            loading={loading}
            defaultValue={zonePreview}
            message={message}
            cordinates={editedCordTable}
            mapCordinates={editedCordMap}
          />
        ) : (
          <Form
            loading={loading}
            message={message}
            defaultValue={null}
            newPolygonCord={newCord}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { zonePreview, loading, zoneError, message } = state.Zone;
  return { zonePreview, loading, zoneError, message };
};

export default connect(mapStateToProps, { fetchEachZone })(ZoneForm);
