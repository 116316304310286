import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_DOCTYPE,
  FETCH_EACH_DOCTYPE,
  CREATE_DOCTYPE,
  DELETE_DOCTYPE,
  UPDATE_DOCTYPE,
  INITIALIZE_DOCTYPE
} from './actionTypes';
import {
  fetchDocTypeSuccessful,
  fetchDocTypeError,
  fetchEachDocTypeSuccessful,
  fetchEachDocTypeError,
  deleteDocTypeSuccessful,
  deleteDocTypeFailed,
  createDocTypeSuccessful,
  createDocTypeFailed,
  updateDocTypeSuccessful,
  updateDocTypeFailed,
  fetchDocTypeAuthorizeError,
  initDocTypeFailed,
  initDocTypeSuccessful
} from './actions';

import {
  GetDocTypeService,
  GetEachDocTypeService,
  DeleteDocTypeService,
  CreateDocTypeService,
  UpdateDocTypeService,
  initDocTypeService
} from '../../services/docTypeService';

function* fetchDocTypeHandler({ payload }) {
  try {
    const response = yield call(GetDocTypeService, payload);
    yield put(fetchDocTypeSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchDocTypeAuthorizeError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    }
    yield put(fetchDocTypeError(error?.response?.data.error?.message));
  }
}
function* fetchEachDocTypeHandler({ payload }) {
  try {
    const response = yield call(GetEachDocTypeService, payload);
    yield put(fetchEachDocTypeSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachDocTypeError(error?.response?.data.error?.message));
  }
}
function* deleteDocTypeHandler({ payload }) {
  try {
    const response = yield call(DeleteDocTypeService, payload);
    yield put(deleteDocTypeSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteDocTypeFailed(error?.response?.data?.message));
  }
}

function* createDocTypeHandler({ payload }) {
  try {
    const response = yield call(CreateDocTypeService, payload);
    yield put(createDocTypeSuccessful(response.data.result));
  } catch (error) {
    yield put(createDocTypeFailed(error?.response?.data?.message));
  }
}

function* updateDocTypeHandler({ payload }) {
  try {
    const response = yield call(UpdateDocTypeService, payload);
    yield put(updateDocTypeSuccessful(response.data.result));
  } catch (error) {
    yield put(updateDocTypeFailed(error?.response?.data?.message));
  }
}

function* initDocTypeHandler({ payload }) {
  try {
    const response = yield call(initDocTypeService, payload);
    yield put(initDocTypeSuccessful(response.data.result));
  } catch (error) {
    yield put(initDocTypeFailed(error?.response?.data?.message));
  }
}

export function* watchFetchDocType() {
  yield takeEvery(FETCH_DOCTYPE, fetchDocTypeHandler);
}
export function* watchFetchEachDocType() {
  yield takeEvery(FETCH_EACH_DOCTYPE, fetchEachDocTypeHandler);
}
export function* watchDeleteDocType() {
  yield takeEvery(DELETE_DOCTYPE, deleteDocTypeHandler);
}
export function* watchCreateDocType() {
  yield takeEvery(CREATE_DOCTYPE, createDocTypeHandler);
}
export function* watchUpdateDocType() {
  yield takeEvery(UPDATE_DOCTYPE, updateDocTypeHandler);
}
export function* watchInitDocType() {
  yield takeEvery(INITIALIZE_DOCTYPE, initDocTypeHandler);
}

function* docTypeSaga() {
  yield all([
    fork(watchFetchDocType),
    fork(watchFetchEachDocType),
    fork(watchDeleteDocType),
    fork(watchCreateDocType),
    fork(watchUpdateDocType),
    fork(watchInitDocType),
  ]);
}

export default docTypeSaga;
