import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import AlartError from '../../../components/Common/AlertCard'
import {
  Col,
  Row,
  Card,
  CardBody,
  Alert,
  Label,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap'
import {
  updateUserProfile,
  activateAccount,
  updateUserProfileError,
  updateAccountWallet,
  generateOTPFailed,
  generateOTP,
} from '../../../store/actions'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { withRouter, useHistory } from 'react-router-dom'
import WalletForm from './WalletForm'
import AssignedRole from './AssignedRole'

const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'

const UserInfoCard = ({
  isProfile,
  user,
  otp,
  otpError,
  otpMessage,
  loadingOTP,
  activationError,
  AccountUser,
  loading,
  message,
  forgetError,
  generateOTP,
  generateOTPFailed,
  updateUserProfile,
  activateAccount,
  updateUserProfileError,
}) => {
  const [avatar] = useState(avatarImg)
  const [activateModal, setActivateModal] = useState(false)
  const [activateModalRole, setActivateModalRole] = useState(false)
  const [walletModal, setWalletModal] = useState(false)
  const [DisableOTP, setDisableOTP] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  let history = useHistory()

  const handleGenerateOTP = () => {
    setDisableButton(true)
    generateOTP({ email: AccountUser.email, type: 'USER_REGISTER' })
  }

  useEffect(() => {
    if (message || forgetError) {
      setTimeout(() => {
        updateUserProfileError('')
      }, 2000)
    }
  }, [message, forgetError])

  useEffect(() => {
    if (disableButton) {
      setTimeout(() => {
        setDisableButton(false)
      }, 30000)
    }

    if (otpMessage) {
      setTimeout(() => {
        generateOTPFailed('')
      }, 3000)
    }
  }, [otpMessage, disableButton])

  const submitActivation = (event, values) => {
    // if (user.userType === 'ADMIN') {
    //   userData.password = values.password;
    // }

    let payload = {
      otp: values.otp,
      id: user.id,
    }

    activateAccount(payload)
    setActivateModal(false)
  }

  const AssignedRoleHandler = (id) => {
    setActivateModalRole(true)
  }

  const activationModalHandler = () => {
    setActivateModal(true)
  }

  const handleActivation = (info, ActivationType) => {
    const userData = {
      isActive: ActivationType,
    }
    updateUserProfile(userData, info.id)
  }

  let checkRole = user?.roles?.find((data) => data.name === 'AMBASSADOR')

  return (
    <div>
      {message && (
        <Alert color="success" className="text-center">
          {message}
        </Alert>
      )}
      {forgetError && <AlartError error={forgetError} />}

      <Card>
        <CardBody>
          <div className="position-relative">
            <div
              className="position-absolute"
              style={{ top: '-20px', right: 0 }}
            >
              {user.userType === 'DRIVER' && (
                <h1
                  className={`badge badge-soft-${
                    user?.checks?.admin ? 'success' : 'danger'
                  } font-size-12 float-right`}
                >
                  {user?.checks?.admin ? 'Approved' : 'Unapproved'}
                </h1>
              )}
              {/* {user.userType === 'RIDER' && checkRole && (
                <h1 className='badge badge-soft-success font-size-12 float-right'>
                  Ambassador
                </h1>
              )} */}
            </div>

            <Row className="mt-2 align-items-center responsive-profile-container">
              <Col className="mb-2 px-0 py-0 responsive-profile" sm={6}>
                <div className="align-items-center d-flex responsive-profile-text">
                  <div className="ml-3 mr-1 d-flex position-relative">
                    <img
                      className="rounded-circle img-thumbnail p-2"
                      alt="Profile"
                      src={user.picture ? user.picture : avatar}
                      onClick={() => {
                        setShowModal(true)
                      }}
                      style={
                        user.picture
                          ? {
                              width: '10rem',
                              height: '10rem',
                              cursor: 'pointer',
                            }
                          : {
                              width: '10rem',
                              height: '10rem',
                              pointerEvents: 'none',
                            }
                      }
                    />
                    <div
                      className={`rounded-circle badge badge-soft-${
                        user?.isActive === true ? 'success' : 'danger'
                      } font-size-16 align-self-end`}
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '20px',
                      }}
                    >
                      {user?.isActive === true ? (
                        <i className="fas fa-dot-circle"></i>
                      ) : (
                        <i className=" fas fa-minus-circle"></i>
                      )}
                    </div>
                  </div>
                  <div>
                    <h3 className="font-weight-bold font-size-20 mb-2 text-capitalize">
                      {user.firstName} {user.lastName}
                      <span className="text-muted font-size-10">
                        <span className="mx-1"> | </span>
                        <span className="mr-1">
                          <i className=" fas fa-star text-warning mr-1" />
                          {user.rating}
                        </span>
                        <span className="text-muted">
                          ({user.raters}{' '}
                          <i className="fas fa-user font-size-10 mr-1 text-muted" />
                          )
                        </span>
                      </span>
                    </h3>

                    <div className="mb-2">
                      <h3
                        className="font-size-14 text-capitalize"
                        style={{ color: 'grey' }}
                      >
                        {user?.userType?.toLowerCase()}
                        {user?.account?.status === 'SANCTION' && (
                          <span>
                            {' '}
                            /{' '}
                            <span
                              className={`badge badge-soft-warning font-size-12`}
                            >
                              SANCTION
                            </span>
                          </span>
                        )}
                      </h3>
                      {user?.displayName && <h5> {user?.displayName} </h5>}
                    </div>
                    {user.loyalty?.tier && (
                      <h5 className="font-size-14 d-flex align-items-center text-warning text-capitalize">
                        <i className="ri-vip-crown-2-line mr-1" />
                        {user.loyalty?.tier?.toLowerCase()}
                      </h5>
                    )}
                  </div>
                </div>
              </Col>

              <Col sm={6} className="pl-5 button-container">
                {!isProfile && (
                  <>
                    <Row className="ml-2 mb-3 buttons">
                      <Button
                        className="btn btn-success px-3 mr-3 mb-1"
                        onClick={() => setWalletModal(true)}
                      >
                        Update balance
                      </Button>

                      {user.isActive ? (
                        <button
                          className="btn btn-outline-danger px-5 mb-1"
                          onClick={() => handleActivation(user, false)}
                        >
                          Deactivate
                        </button>
                      ) : (
                        <button
                          className="btn btn-outline-success px-5 mb-1"
                          disabled={loading}
                          onClick={() => activationModalHandler(user)}
                        >
                          {loading ? 'Submitting' : 'Activate'}
                        </button>
                      )}
                      {user?.userType === 'RIDER' && !checkRole && (
                        <Button
                          // size="sm"
                          color="success"
                          onClick={() => AssignedRoleHandler(user.id)}
                          className="ml-2 mb-1"
                        >
                          <i className="mdi mdi-plus-thick font-size-12"></i>
                          Assigned Role
                        </Button>
                      )}

                      {!user?.checks?.admin && user.userType === 'DRIVER' && (
                        <>
                          <button
                            className="btn btn-outline-success px-2 ml-3 mb-1"
                            id="approve-driver"
                            onClick={() =>
                              updateUserProfile(
                                { approve: 'approveDriver' },
                                user.id,
                              )
                            }
                          >
                            <i className="fas fa-check font-se-12" />
                          </button>

                          <UncontrolledTooltip
                            placement="top"
                            target="approve-driver"
                          >
                            Approve Driver
                          </UncontrolledTooltip>
                        </>
                      )}
                    </Row>
                    <Row className="ml-2">
                      <div className="d-flex flex-column mr-5">
                        <h6 className="mb-2 text-upper font-size-12 font-weight-bold text-secondary">
                          Balance{' '}
                        </h6>
                        <h6 className="text-dark font-weight-bold">
                          {user.account?.currency}{' '}
                          {parseInt(user.account?.balance)?.toLocaleString()}
                        </h6>
                      </div>
                      <div className="d-flex flex-column mr-3">
                        <h6 className="mb-2 text-upper font-size-12 font-weight-bold text-secondary">
                          Available Balance{' '}
                        </h6>
                        <h6 className="text-dark font-weight-bold">
                          {user.account?.currency}{' '}
                          {parseInt(
                            user.account?.availableBalance,
                          )?.toLocaleString()}
                        </h6>
                      </div>
                      <div className="d-flex flex-column mr-5">
                        <h6 className="mb-2 text-upper font-size-12 font-weight-bold text-secondary">
                          Badge{' '}
                        </h6>
                        <span
                          className={`font-size-10 d-flex align-items-center text-dark text-capitalize px-2 border-radius bg-${
                            user.account?.badge ? 'warning' : 'light'
                          }`}
                        >
                          <i className="ri-vip-crown-2-line mr-1" />
                          {user.account?.badge || 'No Badge'}
                        </span>
                      </div>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              {user?.roles?.length > 0 &&
                user?.roles?.map((role) => (
                  <div key={role.id} className="ml-2">
                    <h1 className="badge badge-soft-success font-size-12">
                      {role.name}
                    </h1>
                  </div>
                ))}
            </Row>
          </div>
          <Modal
            isOpen={walletModal}
            toggle={() => setWalletModal(!walletModal)}
          >
            <ModalHeader toggle={() => setWalletModal(false)}>
              Update Wallet
            </ModalHeader>
            <ModalBody>
              <WalletForm
                userId={user.id}
                closeModal={() => setWalletModal(false)}
              />
            </ModalBody>
          </Modal>

          <Modal
            isOpen={activateModal}
            toggle={() => setActivateModal(!activateModal)}
          >
            <ModalHeader toggle={() => setActivateModal(false)}>
              Activation{' '}
              {user.userType === 'ADMIN'
                ? 'Password is Required'
                : 'Confirmmation is Required'}
            </ModalHeader>
            <ModalBody>
              {otpError && (
                <Alert color="danger" className="text-center">
                  {otpError}
                </Alert>
              )}
              {otpMessage && (
                <Alert color="secondary" className="text-center">
                  {otpMessage}
                </Alert>
              )}
              <AvForm
                className="form-horizontal"
                onValidSubmit={submitActivation}
              >
                <Label htmlFor="userotp">OTP</Label>
                <Row>
                  <Col md={8} className="pr-0">
                    <FormGroup className=" mb-4">
                      <AvField
                        name="otp"
                        type="number"
                        className="form-control mb-0"
                        required
                        id="userotp"
                        errorMessage="please request and enter OTP sent to you"
                        placeholder="Enter OTP"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} className="pl-1">
                    <Button
                      color="primary"
                      onClick={handleGenerateOTP}
                      disabled={loadingOTP || disableButton}
                    >
                      {loadingOTP ? 'Sending' : 'Request OTP'}
                    </Button>
                  </Col>
                </Row>
                <div className="mt-2 text-center">
                  <Button
                    color="primary"
                    className="w-md waves-effect waves-light"
                    type="submit"
                  >
                    Activate
                  </Button>
                </div>
              </AvForm>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={showModal}
            toggle={() => setShowModal(!showModal)}
            size="lg"
            style={{ left: '5%' }}
            centered={true}
          >
            <ModalBody className="p-0 m-2" style={{ height: '70vh' }}>
              <img
                src={user.picture}
                alt="Profile"
                style={{ width: '-webkit-fill-available', height: '100%' }}
              />
            </ModalBody>
          </Modal>

          <Modal
            isOpen={activateModalRole}
            toggle={() => setActivateModalRole(!activateModalRole)}
          >
            <ModalHeader toggle={() => setActivateModalRole(false)}>
              Assigned Role
            </ModalHeader>
            <ModalBody>
              <AssignedRole
                userId={user.id}
                setActivateModalRole={setActivateModalRole}
              />
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { loading, forgetError, message } = state.Users
  const {
    user,
    otp,
    otpError,
    otpMessage,
    loadingOTP,
    activationError,
  } = state.Account
  return {
    loading,
    forgetError,
    message,
    AccountUser: user,
    otp,
    otpError,
    otpMessage,
    loadingOTP,
    activationError,
  }
}

export default connect(mapStateToProps, {
  updateUserProfile,
  activateAccount,
  updateAccountWallet,
  updateUserProfileError,
  generateOTPFailed,
  generateOTP,
})(withRouter(UserInfoCard))
