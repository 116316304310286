import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Col, Card, CardBody, Media } from "reactstrap";
import { connect } from "react-redux";
import { fetchUserSummary, fetchBadgeSummary } from "../../../../store/actions";

const MiniWidgets = ({
  summaryError,
  summaryCount,
  badgeSummaryError,
  badgeSummaryCount,
  fetchBadgeSummary,
  fetchUserSummary,
  start,
  userType,
  end,
  loading,
  date,
  defaultIcon,
}) => {
  useEffect(() => {
    if (date.end) {
      if (userType) {
        fetchUserSummary({ userType, start: date.start, end: date.end });
        fetchBadgeSummary({ userType, start: date.start, end: date.end });
      } else {
        fetchUserSummary(date);
        fetchBadgeSummary(date);
      }
    } else {
      if (userType) {
        fetchUserSummary({ userType });
        fetchBadgeSummary({ userType });
      } else {
        fetchUserSummary();
        fetchBadgeSummary();
      }
    }
  }, [userType, date]);

  const reports = [
    {
      icon: defaultIcon ? defaultIcon : "fas fa-users",
      title: "Total Users",
      value: `${summaryCount?.result.total}`,
      rate: `${
        summaryCount?.previousRegisteredDrivers
          ? summaryCount?.previousRegisteredDrivers
          : "0"
      }%`,
      color: "primary",
      desc: "From previous period",
    },
    {
      icon: "ri-police-car-fill",
      title: "Active/Inactive",
      value: summaryCount?.result.active,
      subValue: `${
        summaryCount?.result?.pending ? summaryCount?.result?.pending : "0"
      }`,
      rate: `${
        summaryCount?.previousActiveDrivers
          ? summaryCount?.previousActiveDrivers
          : "0"
      }%`,
      color: "success",
      desc: "From previous period",
    },
    // {
    //   icon: "fas fa-exclamation-triangle",
    //   title: "INACTIVE",
    //   value: `${
    //     summaryCount?.result?.pending ? summaryCount?.result?.pending : "0"
    //   }`,
    //   rate: `${
    //     summaryCount?.previousActiveUsers
    //       ? summaryCount?.previousActiveUsers
    //       : "0"
    //   }%`,
    //   color: "danger",
    //   desc: "From previous period",
    // },
  ];

  return (
    <React.Fragment>
      {summaryError === null &&
        reports.map((report, key) => (
          <Col key={key} md={3}>
            <Card>
              <CardBody>
                <Media>
                  <Media body className="overflow-hidden">
                    <span
                      className={`text-${report.color} badge badge-soft-${report.color} rounded`}
                    >
                      <i className={report.icon + " font-size-16"}></i>
                    </span>
                    <p className="text-truncate font-size-14 mt-4 mb-2 ">
                      {report.title}
                    </p>
                    <h2 className="mt-0 mb-0 font-size-">
                      {summaryCount === null ? (
                        <Skeleton duration={2} width="20%" />
                      ) : report?.subValue ? (
                        <>
                          {report.value.toLocaleString()} /
                          <span className="font-size-15 text-danger">
                            {" "}
                            {report?.subValue?.toLocaleString()}
                          </span>
                        </>
                      ) : (
                        `${report.value.toLocaleString()}`
                      )}
                    </h2>
                  </Media>
                </Media>
              </CardBody>

              {/* <CardBody className="border-top py-3">
                <div className="text-truncate">
                  <span className="badge badge-soft-success font-size-11 mr-1">
                    <i className="mdi mdi-menu-up"> </i> {report.rate}
                  </span>
                  <span className="text-muted ml-2">{report.desc}</span>
                </div>
              </CardBody> */}
            </Card>
          </Col>
        ))}
      <Col md={3}>
        <Card className="d-flex flex-row">
          <CardBody className="pr-1">
            <Media>
              <Media body className="overflow-hidden">
                <span
                  className={`text-primary badge badge-soft-primary rounded`}
                >
                  <i className="ri-vip-crown-2-line mr-1" />
                </span>
                <p className="text-truncate font-size-14 mt-4 mb-2 ">
                  Total Badge
                </p>
                <h2 className="mt-0 mb-0 font-size-">
                  {badgeSummaryCount === null ? (
                    <Skeleton duration={2} width="20%" />
                  ) : (
                    badgeSummaryCount.result.total
                  )}
                </h2>
                {/* {badgeSummaryCount?.isCache && badgeSummaryCount?.time && (
                  <p
                    className="font-size-12 text-primary"
                    style={{ position: "absolute", right: "11px" }}
                  >
                    Updated at: {badgeSummaryCount?.time?.split(",")?.pop()}
                  </p>
                )} */}
              </Media>
            </Media>
          </CardBody>
          <CardBody className="p-1 pr-3">
            {summaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">V</h6>-{" "}
                <span className="text-muted font-size-12">
                  {badgeSummaryCount?.result?.verified}
                </span>
              </div>
            )}

            {badgeSummaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">V_G</h6>-{" "}
                <span className="text-muted font-size-12">
                  {badgeSummaryCount?.result?.verified_government}
                </span>
              </div>
            )}

            {badgeSummaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">V_A</h6>-{" "}
                <span className="text-muted font-size-12">
                  {badgeSummaryCount?.result?.verified_ambassador}
                </span>
              </div>
            )}

            {badgeSummaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">V_B</h6>-{" "}
                <span className="text-muted font-size-12">
                  {badgeSummaryCount?.result?.verified_berekete}
                </span>
              </div>
            )}

            {badgeSummaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">V_S</h6>-{" "}
                <span className="text-muted font-size-12">
                  {badgeSummaryCount?.result?.verified_student}
                </span>
              </div>
            )}

            {badgeSummaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">V_U</h6>-{" "}
                <span className="text-muted font-size-12">
                  {badgeSummaryCount?.result?.verified_union}
                </span>
              </div>
            )}
          </CardBody>

          {/* <CardBody className="border-top py-3">
              <div className="text-truncate">
                <span className="badge badge-soft-success font-size-11 mr-1">
                  <i className="mdi mdi-menu-up"> </i> {report.rate}
                </span>
                <span className="text-muted ml-2">{report.desc}</span>
              </div>
            </CardBody> */}
        </Card>
      </Col>
      <Col md={3}>
        <Card className="d-flex flex-row">
          <CardBody className="pr-1">
            <Media>
              <Media body className="overflow-hidden">
                <span
                  className={`text-warning badge badge-soft-warning rounded`}
                >
                  <i className="ri-star-line  font-size-16"></i>
                </span>
                <p className="text-truncate font-size-14 mt-4 mb-2 ">RATINGS</p>
                <h2 className="mt-0 mb-0 font-size-">
                  {summaryCount === null ? (
                    <Skeleton duration={2} width="20%" />
                  ) : (
                    summaryCount.result.rating.toFixed(2)
                  )}
                </h2>
                {summaryCount?.isCache && summaryCount?.time && (
                  <p
                    className="font-size-12 text-primary"
                    style={{ position: "absolute", right: "11px" }}
                  >
                    Updated at: {summaryCount?.time?.split(",")?.pop()}
                  </p>
                )}
              </Media>
            </Media>
          </CardBody>
          <CardBody className="p-1 pr-3">
            {summaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">
                  {summaryCount?.result?.ratingsCount?.zero_rating}
                </h6>
                -{" "}
                <span className="text-muted font-size-12">
                  (<i className=" ri-star-s-fill text-warning font-size-10" />{" "}
                  0)
                </span>
              </div>
            )}

            {summaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">
                  {summaryCount?.result?.ratingsCount?.one_rating}
                </h6>
                -{" "}
                <span className="text-muted font-size-12">
                  (<i className=" ri-star-s-fill text-warning font-size-10" />{" "}
                  1)
                </span>
              </div>
            )}

            {summaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">
                  {summaryCount?.result?.ratingsCount?.two_rating}
                </h6>
                -{" "}
                <span className="text-muted font-size-12">
                  (<i className=" ri-star-s-fill text-warning font-size-10" />{" "}
                  2)
                </span>
              </div>
            )}

            {summaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">
                  {summaryCount?.result?.ratingsCount?.three_rating}
                </h6>
                -{" "}
                <span className="text-muted font-size-12">
                  (<i className=" ri-star-s-fill text-warning font-size-10" />{" "}
                  3)
                </span>
              </div>
            )}

            {summaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">
                  {summaryCount?.result?.ratingsCount?.four_rating}
                </h6>
                -{" "}
                <span className="text-muted font-size-12">
                  (<i className=" ri-star-s-fill text-warning font-size-10" />{" "}
                  4)
                </span>
              </div>
            )}

            {summaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">
                  {summaryCount?.result?.ratingsCount?.five_rating}
                </h6>
                -{" "}
                <span className="text-muted font-size-12">
                  (<i className=" ri-star-s-fill text-warning font-size-10" />{" "}
                  5)
                </span>
              </div>
            )}
          </CardBody>

          {/* <CardBody className="border-top py-3">
              <div className="text-truncate">
                <span className="badge badge-soft-success font-size-11 mr-1">
                  <i className="mdi mdi-menu-up"> </i> {report.rate}
                </span>
                <span className="text-muted ml-2">{report.desc}</span>
              </div>
            </CardBody> */}
        </Card>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { summaryError, summaryCount, badgeSummaryError, badgeSummaryCount } =
    state.Users;
  return { summaryError, summaryCount, badgeSummaryError, badgeSummaryCount };
};

export default connect(mapStateToProps, {
  fetchUserSummary,
  fetchBadgeSummary,
})(MiniWidgets);
