import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';
import Users from './users/reducer';
import Packages from './packages/reducer';
import AppConfigs from './appConfigs/reducer';
import Activities from './activities/reducer';
import Rides from './rides/reducer';
import PromoCode from './promoCode/reducer';
import DocType from './docType/reducer';
import Document from './document/reducer';
import Vehicle from './vehicle/reducer';
import Complaint from './complaint/reducer';
import Bank from './bank/reducer';
import Payment from './payment/reducer';
import Cashout from './cashout/reducer';
import Bonus from './bonus/reducer';
import Review from './review/reducer';
import Reward from './reward/reducer';
import Notifications from './notifications/reducer';
import Loyalty from './loyalty/reducer';
import LoyaltyTier from './loyaltyTier/reducer';
import Dashboard from './dashboard/reducer';
import Zone from './zone/reducer';
import Role from './roles/reducer';
import Contact from './contact/reducer';
import App_version from './appVersion/reducer';
import LicenseKey from './licenseKey/reducer';
import AnalyticCashout from './analytics/cashout/reducer';
import NetworkChecker from './networkChecker/reducer';
import BusStop from './busStop/reducer'
import Route from './route/reducer'

// Authentication Module
import Account from './auth/reducer';
import Referral from './invitation/reducer';
// import users from './auth/user';

const rootReducer = combineReducers({
  // public
  AnalyticCashout,
  Layout,
  Users,
  Payment,
  Packages,
  AppConfigs,
  Account,
  Rides,
  Bonus,
  Reward,
  PromoCode,
  DocType,
  Document,
  Vehicle,
  Complaint,
  Bank,
  Cashout,
  Notifications,
  Review,
  Loyalty,
  Dashboard,
  LoyaltyTier,
  Zone,
  Contact,
  Activities,
  Referral,
  Role,
  App_version,
  LicenseKey,
  NetworkChecker,
  BusStop,
  Route
});

export default rootReducer;
