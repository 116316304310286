export default {
  DASHBOARD: 'Dashboard',
  CONFIGURATION: 'Configurations',
  APP_SETTING: 'App Settings',
  APP_VERSION: 'App Version',
  DOCUMENT_TYPE: 'Document Type',
  ACCESS_CONTROL: 'Access Control',
  ACCESS_CONTROL_FE: 'Access Control FE',
  LICENSE: 'License',

  USERS: 'Users',
  ADMINS: 'Admins',
  RIDERS: 'Riders',
  DRIVER_LOGS: 'Driver Logs"',
  DRIVER_HOURS: 'Driver Hours"',
  INSPECTORS: 'Inspectors',
  DOCUMENTS: 'Documents',

  INSPECTIONS: 'Inspections',
  FLEETS: 'Fleets',
  AGENTS: 'Agents',

  REWARDS: 'Rewards',
  RIDERS_REWARDS: 'Riders Rewards',

  ALL: 'All',
  PROMO_CODE: 'Promo Code',

  RATING_REVIEW: 'Rating & Review',
  DRIVER_REVIEW: 'Driver Review',
  RIDER_REVIEWS: 'Rider Reviews',
  ANALYTICS: 'Analytics',
  CASHOUT: 'Cashout',
  VEHICLES: 'Vehicles',
  BUS_STOPS: 'Bus Stops',
  ROUTE: 'Route',
  FINANCES: 'Finance',
  BANK_DETAIL: 'Bank Detail',
  PAYMENTS: 'Payments',
  RIDES: 'Rides',
  HISTORY: 'History',
  MONITORING: 'Monitoring',
  RIDE_PACKAGE: 'Ride Package',
  ZONES: 'Zones',

  RIDES_PROMOTION: 'Rides Promotions',
  DRIVER_REWARDS: 'Driver Rewards',
  REWARD_LOGS: 'Rewards Logs',
  BONUS_LOGS: 'Bonus Logs',
  BONUS: 'Bonus',
  RIDER_BONUS_HISTORY: 'Rider Bonus History',
  LOYALTY: 'Loyalty',
  LOYALTY_TIER: 'Loyalty Tier',
  DRIVER_LOYALTIES: 'Driver Loyalties',
  RIDER_LOYALTIES: 'Rider Loyalties',
  USAGE_HISTORY: 'Usage History',
  REFERRAL: 'Referral',
  PUSH_NOTIFICATION: 'Push Notification',
  CUSTOM_NOTIFICATION: 'Custom Notification',
  ANNOUCEMENTS: 'Announcements',
  SCHEDULE_NOTIFICATION: 'Schedule Notifications',
  NOTIFICATION_LOG: 'Notification Log',
  GENERAL_NOTIFICATION: 'General Notification',
  COMPLAINTS: 'Complaints',
  GENERAL_COMPLAINTS: 'General Complaint',
  MY_COMPLAINTS: 'My Compliant',
  EMERGENCY: 'Emergency',
  EMERGENCY_CONTACT: 'Emergency Contacts',
  SOS_INFO: 'SOS Info',
  REPORTS: 'Reports',
  ACTIVITIES_LOG: 'Activities Log',
  DRIVER_REPORT: 'Driver Report',
  FEEDBACK: 'FeedBack',
};
