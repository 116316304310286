import HttpService from './HttpService'
import { QueryReallignment } from '../utils/general'

export const CreateUserService = (payload) => {
  const http = new HttpService()
  const url = 'user/create'
  return http.postData(payload, url)
}

export const fetchAllUserService = (query) => {
  // Payload with query
  const http = new HttpService()
  let url = 'user/all'
  if (query) {
    let queryParams = QueryReallignment(query)

    if (queryParams.includes('search')) {
      url = `user/search?${queryParams}`
    } else {
      url = `${url}?${queryParams}`
    }
  }
  return http.getData(url)
}
export const fetchUserExportService = (query) => {
  // Payload with query
  const http = new HttpService()
  let url = 'user/export'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}
export const fetchUserSummaryService = (query) => {
  // Payload with query
  const http = new HttpService()
  let url = 'summary/user'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const fetchBadgeSummaryService = (query) => {
  // Payload with query
  const http = new HttpService()
  let url = 'summary/badge'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const fetchUserDetailsService = (id) => {
  const http = new HttpService()
  const url = `user/${id}`
  return http.getData(url)
}

export const openDriverAppService = () => {
  const http = new HttpService()
  const url = `/invite/driver`
  return http.getData(url)
}

export const freezeAccountService = ({ payload, id }) => {
  const http = new HttpService()
  const url = `user/${id}/freeze`
  return http.putData(payload, url)
}

export const fetchAccountEntriesServices = ({ id, query }) => {
  const http = new HttpService()
  let url = `account/${id}/entry`
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const DeleteUserService = (payload) => {
  const http = new HttpService()
  const url = `user/${payload.id}`
  return http.deleteDataWithData(payload, url)
}

export const updateUserProfileServices = ({ payload, id }) => {
  const http = new HttpService()
  let url = `user/${id}`
  if (payload.approve) {
    url = `user/${id}/${payload.approve}`
  }
  return http.putData(payload, url)
}

export const approveUserAccountService = ({ data, id }) => {
  const http = new HttpService()
  let url = `account/${id}/verify`

  return http.putData(data, url)
}

export const activateUserProfileServices = ({ payload }) => {
  const http = new HttpService()
  let url = `user/activate`
  return http.putData(payload, url)
}

export const fetchDriverLogsServices = ({ query }) => {
  const http = new HttpService()
  let url = 'driver-logs/latest'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const AllDriverLogsServices = ({ query }) => {
  const http = new HttpService()
  let url = 'driver-logs'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const driverLogsSummaryService = (query) => {
  // Payload with query
  const http = new HttpService()

  let url = 'driver-logs/summary'
  if (query.end && !query.latest) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  if (query.latest) {
    // let queryParams = QueryReallignment(query);
    url = 'driver-logs/latest/summary'
  }
  console.log(url)
  return http.getData(url)
}

export const fetchDriverOnlineLogsServices = ({ query }) => {
  const http = new HttpService()
  let url = 'driver-online/log'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const fetchDriverHoursLogsServices = ({ query }) => {
  const http = new HttpService()
  let url = 'driver-online/all'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const fetchUserRoleService = () => {
  const http = new HttpService()
  let url = 'role'
  return http.getData(url)
}

export const updateUserRoleService = (payload) => {
  const http = new HttpService()
  let url = `user/${payload.id}/role`
  return http.putData(payload.data, url)
}
